import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  name: "bankAccountList",

  data() {
    return {
      bankAccountList: [],
      searchText: "",
      showLoader: false,
      selected: "",
      selectedId: 0,
      selectedRows: [],
      sortColumn: "payer_name",
      sortDirection: "asc",
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.bankAccountList.length > 0) {
        return (
          _.where(this.bankAccountList, { isSelected: true }).length ===
          this.bankAccountList.length
        );
      }
      return false;
    },
    hasBankAccountShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBankAccountAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBankAccountDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBankAccountUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    checkUncheckedList(event) {
      this.bankAccountList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a specific id is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Iterate through bankAccountList to find selected elements
        this.bankAccountList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      // Send a request to delete the records
      _vm.axios
        .post("/bank-accounts-deletes", { ids: ids })
        .then(function () {
          // Refresh the list after deletion
          _vm.getAll();
          _vm.selectedId = 0; // Reset the selectedId
        })
        .catch(function () {
          // Handle any errors that occur during deletion
        });
    },
    search(value) {
      this.getAll();
    },
    getQueryString() {
      // Construct the query string based on the current page, record take, sort column, sort direction, and search text
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      // Append the search text to the query string if it is not empty
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      // Return the final query string
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      // Check if the column is already being sorted
      if (this.sortColumn === columnName) {
        // If it is, toggle the sort direction
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        // If it's a new column, set the sort column and direction
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      // Call the getAll() function to update the sorted results
      this.getAll();
    },

    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Send GET request to retrieve bank accounts
      this.axios
        .get("/bank-accounts" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.bankAccountList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    handleCheck(e) {
      // Check if the target element's id is "customCheck-1"
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // If the checkbox is checked, select all rows and update their isSelected property
          this.selectedRows = this.bankAccountList.map(
            (item) => "checkbox" + item.id
          );
          this.bankAccountList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // If the checkbox is unchecked, deselect all rows and update their isSelected property
          this.selectedRows = [];
          this.bankAccountList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // If a checkbox other than "customCheck-1" is checked, add its id to the selectedRows array
          this.selectedRows.push(e.target.id);
        } else {
          // If a checkbox other than "customCheck-1" is unchecked, remove its id from the selectedRows array
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
